
import { Component, Vue } from 'vue-property-decorator';
import { getIndustryList } from '@/api/industry';
import { getRecentList, removeRecentList } from '@/utils/localStorage';
import Pagination from '@/components/Pagination/index.vue';
import { faRupiahSign } from '@fortawesome/free-solid-svg-icons';
import { IndustryItem } from './industryitem';

@Component({
  components: {
    Pagination,
  },
})

export default class extends Vue {
  mounted() {
    this.getIndustryList();
    // 최근 목록
    const tmp = getRecentList();
    this.recentList = tmp;
    this.recentFilterList = this.recentList;
    this.fusionLeisure = this.recentFilterList.filter((item : any) => item.industryIdx === 2 || item.industryIdx === 3);
    console.log(this.fusionLeisure);
  }

  private listQuery = {
    page: 0,
    size: 12,
    industryIdx: '전체',
  }

  private tabActive = 1;

  private activeTab = this.$route.query.tabName ? this.$route.query.tabName : 'all';

  private loading = true;

  private industryList = [];

  private recentList:any = [];

  private recentFilterList = [];

  private removeRecentList(idx: number, industryIdx: number, event: Event) {
    try {
      removeRecentList(idx, industryIdx); // localStorage에서 해당 아이템 삭제
      this.$message.error('삭제 되었습니다.');
      const tmp = getRecentList();
      this.recentList = tmp;
      this.recentFilterList = this.recentList;
      this.listQuery.industryIdx = '전체';
    } catch (error) {
      this.$message.error('삭제중 오류가 발생되었습니다');
      this.listQuery.industryIdx = '전체';
    }
  }

  private fusionLeisure = [];

  private getIndustryList() {
    getIndustryList().then((res) => {
      this.industryList = res.data;
      this.industryList = this.industryList.filter((item: IndustryItem) => item.name !== '일반상품');
      this.industryList = this.industryList.filter((item: IndustryItem) => {
        if (item.name === '일반상품') {
          return false;
        }
        if (item.name === '레저') {
          return false;
        }
        if (item.name === '체험') {
          item.name = '체험 / 레저';
        }
        return true;
      });
    });
  }

  private goRecent(link: string) {
    this.$router.push(link);
  }

  private apiUrl = process.env.VUE_APP_COMMON_API;

  private truncateName(name: string, maxLength: number) :string {
    if (name.length > maxLength) {
      return `${name.slice(0, maxLength)}...`;
    }
    return name;
  }

  private goBack() {
    this.$router.go(-1);
  }

  private handleChangeIndustry(item: any, event: any) {
    if (item._props.label !== '전체') {
      this.recentFilterList = this.recentList.filter((filterItem : any) => filterItem.industryName === item._props.label);
    }
    if (item._props.name === 2) {
      this.recentFilterList = this.fusionLeisure;
    }

    this.getIndustryList();
  }

  private distanceType(distance: number | string): number {
    const value = Number(distance);
    if (value > 1000) {
      return value / 1000;
    }
    return 0;
  }
}
